import React from 'react';

import imageUrlBuilder from '@sanity/image-url';
import {SEO} from 'src/components/shared';
import {sanityClient} from 'src/utils/sanity';

import {pageBuilderMap} from './groqPageBuilderMap';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const CustomBuiltSanityPage: React.FC<{
  pageData: {
    components: any;
    seoMetaTitle: string;
    seoDescription: string;
    seoURL: string;
    seoImage: any;
  };
}> = ({pageData}) => {
  return (
    <>
      <SEO
        title={pageData?.seoMetaTitle}
        description={pageData?.seoDescription}
        siteUrl={pageData?.seoURL}
        image={imageUrlBuilder(sanityClient).image(pageData?.seoImage).url()}
      />
      {pageData.components.map((component: {_type: string}) => {
        if (component && pageBuilderMap[component._type]) {
          return pageBuilderMap[component._type](component);
        }
      })}
    </>
  );
};
