import React from 'react';

import {CustomBuiltSanityPage} from './index';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CustomBuiltSanityPageSSR: React.FC<any> = props => {
  return <CustomBuiltSanityPage pageData={props.pageContext.pageData} />;
};

export default CustomBuiltSanityPageSSR;
