import React, {useEffect, useState} from 'react';

import classnames from 'classnames';
import {BasicTheme, makeTailwindBasicThemeClasses} from 'src/utils/makeTailwindBasicThemeClasses';

import {Loading} from '../shared';

export type IFrameProps = {
  src: string;
  theme: BasicTheme;
  heightPx: number;
  maxWidthPx?: number;
};

export const IFrame: React.FC<IFrameProps> = ({src, theme, heightPx, maxWidthPx}) => {
  const themeClasses = makeTailwindBasicThemeClasses(theme);
  const iframeStyles = {
    height: heightPx,
    maxWidth: maxWidthPx ?? '100%',
    width: '100%',
    margin: '0 auto',
    border: 'none',
    overflow: 'hidden',
  };

  const containerStyles = {
    height: heightPx,
    width: '100%',
    overflow: 'hidden',
  };

  const [srcUrl, setSrcUrl] = useState('');

  useEffect(() => {
    const url = new URL(src);
    if (typeof window !== 'undefined') {
      // this statement necessary because window isn't available during the build process
      const srcUrlSearchParams = new URLSearchParams(url.search);
      Array.from(new URLSearchParams(window?.location.search)).forEach(([key, value]) => {
        srcUrlSearchParams.set(key, value);
      });
      url.search = srcUrlSearchParams.toString();
    }
    setSrcUrl(url.toString());
  }, []);

  return (
    <div className={classnames('iframe-container', themeClasses)} style={containerStyles}>
      {srcUrl ? <iframe src={srcUrl} style={iframeStyles} /> : <Loading />}
    </div>
  );
};
